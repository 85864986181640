import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDAspXdr9zFYMm_LRmqRX2s5dDpb8EAR0Q",
  authDomain: "tapl-co.firebaseapp.com",
  projectId: "tapl-co",
  storageBucket: "tapl-co.appspot.com",
  messagingSenderId: "548817182714",
  appId: "1:548817182714:web:37d584399905e24f474e43",
  measurementId: "G-JG6WV2BGMB"
  };
  

  var Firebase =   firebase.initializeApp(firebaseConfig);
  export{
      Firebase
  }