import React from "react";
import "./dot.css";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import UserImg from './../../Images/download.png';
export function Dot(url) {
  return <div className="Blink"><img className="_userlogo" src={url}  alt="Examplee3" width="40" height="40"></img></div>;
}




  